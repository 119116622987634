import { SingleAndMultipleSelectItemResponseValues, SliderItemResponseValues } from 'shared/state';
import { ActivityStatus } from 'shared/consts';
import { AnswerDTO, ExtendedEvent, JourneyCSVReturnProps, UserActionType } from 'shared/types';
import { getDictionaryText } from 'shared/utils/forms';
import { SuccessedEventDTO } from 'shared/types/answer';

import { parseOptions } from './parseOptions';
import { parseResponseValue } from './parseResponseValue';
import { replaceItemVariableWithName } from './replaceItemVariableWithName';
import { convertDateStampToMs } from './convertDateStampToMs';

const getTimeByCondition = (time: string) => (condition: boolean) => (condition ? time : '');

const SPLASH_SCREEN_ITEM_NAME = 'Splash Screen';

export const getJourneyCSVReturn = (
  returnProps: JourneyCSVReturnProps,
  enableDataExportRenaming?: boolean,
) => {
  const {
    id,
    activity_flow_submission_id,
    activity_scheduled_time,
    activity_start_time,
    activity_end_time,
    press_next_time,
    press_popup_skip_time,
    press_popup_keep_time,
    press_back_time,
    press_undo_time,
    press_skip_time,
    press_done_time,
    response_option_selection_time,
    source_user_subject_id = '',
    source_user_secret_id = '',
    source_user_nickname = '',
    source_user_relation = '',
    source_user_tag = '',
    target_user_subject_id = '',
    target_user_secret_id = '',
    target_user_nickname = '',
    target_user_tag = '',
    input_user_subject_id = '',
    input_user_secret_id = '',
    input_user_nickname = '',
    secret_user_id = '',
    user_id = '',
    activity_id,
    activity_flow_id = '',
    activity_flow_name = '',
    activity_name,
    item,
    item_id = '',
    prompt,
    response,
    options,
    version = '',
    legacy_user_id = null,
    event_id = '',
    timezone_offset = null,
  } = returnProps;

  return enableDataExportRenaming
    ? {
        target_id: target_user_subject_id,
        target_secret_id: target_user_secret_id,
        target_nickname: target_user_nickname,
        target_tag: target_user_tag,
        source_id: source_user_subject_id,
        source_secret_id: source_user_secret_id,
        source_nickname: source_user_nickname,
        source_tag: source_user_tag,
        source_relation: source_user_relation,
        input_id: input_user_subject_id,
        input_secret_id: input_user_secret_id,
        input_nickname: input_user_nickname,
        user_id,
        secret_user_id,
        legacy_user_id,
        applet_version: version,
        activity_flow_id,
        activity_flow_name,
        activity_flow_submission_id,
        activity_id,
        activity_name,
        activity_submission_id: id,
        activity_start_time,
        activity_end_time,
        activity_schedule_id: event_id,
        activity_schedule_start_time: activity_scheduled_time,
        utc_timezone_offset: timezone_offset,
        item_id,
        item_name: item,
        item_prompt: prompt,
        item_response_options: options,
        item_response: response,
        press_next_time,
        press_popup_skip_time,
        press_popup_keep_time,
        press_back_time,
        press_undo_time,
        press_skip_time,
        press_done_time,
        response_option_selection_time,
      }
    : {
        id,
        activity_flow_submission_id,
        activity_scheduled_time,
        activity_start_time,
        activity_end_time,
        press_next_time,
        press_popup_skip_time,
        press_popup_keep_time,
        press_back_time,
        press_undo_time,
        press_skip_time,
        press_done_time,
        response_option_selection_time,
        secret_user_id,
        user_id,
        source_user_subject_id,
        source_user_secret_id,
        source_user_nickname,
        source_user_relation,
        source_user_tag,
        target_user_subject_id,
        target_user_secret_id,
        target_user_nickname,
        target_user_tag,
        input_user_subject_id,
        input_user_secret_id,
        input_user_nickname,
        activity_id,
        activity_flow_id,
        activity_flow_name,
        activity_name,
        item,
        item_id,
        prompt,
        response,
        options,
        version,
        event_id,
        timezone_offset,
        legacy_user_id,
      };
};

export const getSplashScreen = (event: SuccessedEventDTO, nextExtendedEvent: ExtendedEvent) => {
  const {
    id,
    scheduledDatetime,
    startDatetime,
    endDatetime,
    respondentSecretId,
    respondentId,
    sourceSubjectId,
    sourceSecretId,
    sourceUserNickname,
    sourceUserTag,
    targetSubjectId,
    targetSecretId,
    targetUserNickname,
    targetUserTag,
    inputSubjectId,
    inputSecretId,
    inputUserNickname,
    relation,
    activityId,
    activityName,
    flowName,
    flowId,
    version,
    legacyProfileId,
    submitId,
  } = nextExtendedEvent;
  const getTime = getTimeByCondition(event.time.toString());

  return getJourneyCSVReturn({
    id,
    activity_flow_submission_id: flowId ? submitId : '',
    activity_scheduled_time: scheduledDatetime
      ? convertDateStampToMs(scheduledDatetime)
      : ActivityStatus.NotScheduled,
    activity_start_time: convertDateStampToMs(startDatetime),
    activity_end_time: convertDateStampToMs(endDatetime),
    press_next_time: getTime(event.type === UserActionType.Next),
    press_popup_skip_time: getTime(event.type === UserActionType.SkipPopupConfirm),
    press_popup_keep_time: getTime(event.type === UserActionType.SkipPopupCancel),
    press_back_time: getTime(event.type === UserActionType.Prev),
    press_undo_time: getTime(event.type === UserActionType.Undo),
    press_skip_time: getTime(event.type === UserActionType.Skip),
    press_done_time: getTime(event.type === UserActionType.Done),
    response_option_selection_time: getTime(event.type === UserActionType.SetAnswer),
    secret_user_id: respondentSecretId,
    user_id: respondentId,
    source_user_subject_id: sourceSubjectId,
    source_user_secret_id: sourceSecretId,
    source_user_nickname: sourceUserNickname,
    source_user_relation: relation,
    source_user_tag: sourceUserTag,
    target_user_subject_id: targetSubjectId,
    target_user_secret_id: targetSecretId,
    target_user_nickname: targetUserNickname,
    target_user_tag: targetUserTag,
    input_user_subject_id: inputSubjectId,
    input_user_secret_id: inputSecretId,
    input_user_nickname: inputUserNickname,
    activity_id: activityId,
    activity_flow_id: flowId,
    activity_flow_name: flowName,
    activity_name: activityName,
    item: SPLASH_SCREEN_ITEM_NAME,
    item_id: '',
    prompt: '',
    response: '',
    options: '',
    version,
    event_id: null,
    timezone_offset: null,
    legacy_user_id: legacyProfileId,
  });
};

export const getJourneyCSVObject = <T>({
  event,
  rawAnswersObject,
  index,
  enableDataExportRenaming,
}: {
  event: ExtendedEvent;
  rawAnswersObject: Record<string, T & { answer: AnswerDTO }>;
  index: number;
  enableDataExportRenaming: boolean;
}) => {
  const {
    activityItem,
    scheduledDatetime,
    startDatetime,
    endDatetime,
    respondentSecretId,
    respondentId,
    sourceSubjectId,
    sourceSecretId,
    sourceUserNickname,
    sourceUserTag,
    targetSubjectId,
    targetSecretId,
    targetUserNickname,
    targetUserTag,
    inputSubjectId,
    inputSecretId,
    inputUserNickname,
    relation,
    activityId,
    activityName,
    flowName,
    flowId,
    version,
    legacyProfileId,
    scheduledEventId,
    tzOffset,
    submitId,
  } = event;
  if (!activityItem) return;

  const responseValues = activityItem?.responseValues as SingleAndMultipleSelectItemResponseValues &
    SliderItemResponseValues;
  const getTime = getTimeByCondition(event.time.toString());

  return getJourneyCSVReturn(
    {
      id: event.id,
      activity_flow_submission_id: flowId ? submitId : '',
      activity_scheduled_time: scheduledDatetime
        ? convertDateStampToMs(scheduledDatetime)
        : ActivityStatus.NotScheduled,
      activity_start_time: convertDateStampToMs(startDatetime),
      activity_end_time: convertDateStampToMs(endDatetime),
      press_next_time: getTime(event.type === UserActionType.Next),
      press_popup_skip_time: getTime(event.type === UserActionType.SkipPopupConfirm),
      press_popup_keep_time: getTime(event.type === UserActionType.SkipPopupCancel),
      press_back_time: getTime(event.type === UserActionType.Prev),
      press_undo_time: getTime(event.type === UserActionType.Undo),
      press_skip_time: getTime(event.type === UserActionType.Skip),
      press_done_time: getTime(event.type === UserActionType.Done),
      response_option_selection_time: getTime(event.type === UserActionType.SetAnswer),
      secret_user_id: respondentSecretId,
      user_id: respondentId,
      source_user_subject_id: sourceSubjectId,
      source_user_secret_id: sourceSecretId,
      source_user_nickname: sourceUserNickname,
      source_user_relation: relation,
      source_user_tag: sourceUserTag,
      target_user_subject_id: targetSubjectId,
      target_user_secret_id: targetSecretId,
      target_user_nickname: targetUserNickname,
      target_user_tag: targetUserTag,
      input_user_subject_id: inputSubjectId,
      input_user_secret_id: inputSecretId,
      input_user_nickname: inputUserNickname,
      activity_id: activityId,
      activity_flow_id: flowId,
      activity_flow_name: flowName,
      activity_name: activityName,
      item: activityItem.name,
      item_id: activityItem.id,
      prompt: replaceItemVariableWithName({
        markdown: getDictionaryText(activityItem.question),
        items: event.items,
        rawAnswersObject,
      }),
      response: parseResponseValue(event, index, true),
      options: replaceItemVariableWithName({
        markdown: parseOptions(responseValues, event.activityItem?.responseType) ?? '',
        items: event.items,
        rawAnswersObject,
      }),
      version,
      event_id: scheduledEventId,
      timezone_offset: tzOffset,
      legacy_user_id: legacyProfileId,
    },
    enableDataExportRenaming,
  );
};
